<!--纵向导航栏组件-->
<template>
  <div>
    <a-menu @select="handleclick" :open-keys.sync="openKeys" mode="inline">
      <template v-for="item in list">
        <a-menu-item v-if="!item.children" :key="item.key">
          <span>{{ item.title }}</span>
        </a-menu-item>
        <sub-menu v-else :key="item.key" :menu-info="item" />
      </template>
    </a-menu>
  </div>
</template>

<script>
import { Menu } from "ant-design-vue";
const SubMenu = {
  template: `
      <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <span>{{ menuInfo.title }}</span>
        </span>
        <template v-for="item in menuInfo.children">
          <a-menu-item v-if="!item.children" :key="item.key">
            <span>{{ item.title }}</span>
          </a-menu-item>
          <sub-menu v-else :key="item.key" :menu-info="item" />
        </template>
      </a-sub-menu>
    `,
  name: "SubMenu",
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
export default {
  props: {
    list: {
      type: Array,
    },
  },
  components: {
    "sub-menu": SubMenu,
  },
  data() {
    return {
      openKeys:["sub1"]
    };
  },
  methods: {
    handleclick({ item, key, selectedKeys }) {
      this.$emit("select", key);
    },
  },
  
};
</script>
